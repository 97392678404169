* {
  box-sizing: table-cell;
  margin: 0;
  padding: 0;
}

a, a:hover {
  text-decoration: none;
}

.content {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-2 {
  background-color: #fff;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

.content-wrapper{
  -webkit-box: 1 1 auto;
	-moz-box: 1 1 auto;
	-ms-flexbox: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.d-flex div {
  padding: 1rem;
  background-color: #36737e;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
}

.main-overlay {
  display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  bottom: 0;
  overflow: hidden;
}

.menu-element {
  padding: 20px;
  background-color: #36737e;
  color: #ffff;
  border-bottom: 1px solid #e2e2e2;
  text-decoration: none;
}
.main{
  height: 100%;
  width: 100%;
  position: fixed;
}

.body {
  display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
}

html, #root{
  height: 100%;
  margin: 0;
  padding: 0;
}

