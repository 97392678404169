* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .mainContent{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    background-color: #b9d5d5;
  }
  
  /* Intro Image */
  .header{
    min-height: 400px;
    background-size: cover;
    background-image: url('https://www.arboair.com/wp-content/uploads/2020/02/trees-2-comp.jpg') !important;
    background-color: #666;
    opacity: 0.9;
    position: relative;
  }
  
  .headerContainer{
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      position: absolute;
      text-align: center;
      text-shadow: 2px 2px 5px rgb(27, 27, 27);
      max-width: 600px;
      line-height: 51px;
      padding: 40px;
  }
  
  .headerContainer h1 {
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 51px;
    color: #ffffff !important;
  }
  
  /* Main Container */
  .desc{
    text-shadow: 2px 2px 8px black;
  }
  
  .bodyContent{
    text-align: center;
    background-color: rgb(255, 255, 255);
    padding-top: 60px;
    padding-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
    text-shadow: none;
  }
  
  .instructContainer h4{
    color: black;
    padding: 20px 0 10px 0;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
    margin: 0 auto;
  }
    
  .instructContainer p{
    color: gray;
    padding: 0px 20% 50px 20%;
  }
  
  .heroLine{
    border-bottom: 3px solid black;
    margin: 0 40% 20px 40%;
  }
  
  .tuts{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin: auto;
  }
  
  .tuts h4{
    font-size: 18px;
  }
  
  .tuts img{
    overflow: hidden;
    object-fit: cover;
    height: 300px;
    width: 500px;
  }
  
  .tuts p{
    width: 500px;
  }
  
  .addForestButton {
    border: none;
    display: block;
    background-color: #396F78;
    color: white;
    border-radius: 50px;
    padding: 15px 0 10px 0;
    cursor: pointer;
    margin-right: 35%;
    margin-left: 35%;
  }
  
  .addForestButton h4{
    font-size: 16px;
  }
  
  .addForestButton:hover{
    color: #313131;
    background-color: #95C7CF;
    /* border: 2px solid gray; */
  }
  
  .line{
    border-bottom: 3px solid #070707;
    width: 80px;
    margin: 5px auto 10px auto;
  }
  
  .v2{
    display: none;
  }
  
  /* Footer Container */
  .footerContainer {
    width: 100%;
    background-color: #74b2b7;
    justify-content: center;
    text-align: center;
    padding: 50px;
  }
  
  .footerContainer p {
    margin: 0;
    color: whitesmoke;
  }
  
  /* For Ipad */
  @media screen and (max-width: 1280px) {
    .headerContainer{
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
    .v2{
      display: block;
    }
  
    .v1{
      display: none;
    }
  
    .sectionOneLP {
      display: flex;
      flex-direction: column;
      align-items: center; 
      padding: 20px 100px 10px 100px;
    }
  
    .sectionTwoLP,
    .sectionThreeLP {
      display: flex;
      flex-direction: column;
    }
  
    .boxThreeLP {
      flex: 1;
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .boxImageFourLP {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      order: 2;
    }
  }
  
  /* ------------------- */
  /* .containerLandingPage {
    background-color: #b9d5d5;
    padding: 0 10rem;
    height: 100vh;
  }
  
  .sectionOneLP {
    display: flex;
    background-color: whitesmoke;
    flex-direction: column;
    align-items: center;
    padding: 1rem 100px 1rem 100px;
  }
  
  .sectionTwoLP,
  .sectionThreeLP {
    background-color: whitesmoke;
    display: flex;
    padding: 50px;
    justify-content: center;
    align-content: center;
  }
  
  .sectionFiveBtnLP {
    display: flex;
    background-color: whitesmoke;
    flex-direction: column;
    align-items: center;
    padding: 1rem 100px 1rem 100px;
  }
  
  .descriptionLandPage {
    display: flex;
    padding: 30px;
  }
  
  .imgDescriptionLP {
    width: 70%;
  }
  
  .boxOneLP {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .boxImageTwoLP {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  
  .boxThreeLP {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 2;
  }
  
  .boxImageFourLP {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    order: 1;
  }
  
  .btnLP {
    background-color: #0d666e;
    color: whitesmoke;
    padding: 10px 50px;
    border: 0 solid;
    border-radius: 5px;
    font-size: 18px;
    letter-spacing: 0.2rem;
    cursor: pointer;
  }
  
  .btnLP:hover {
    background-color: #094247;
  } */
  