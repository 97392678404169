.mainContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    background-color: #b9d5d5;
  }
  
  .headerImage {
    min-height: 250px;
    background-size: cover;
    background-image: url('https://www.arboair.com/wp-content/uploads/2020/02/trees-2-comp.jpg') !important;
    background-color: #666;
    opacity: 0.9;
    position: relative;
  }
  .headerTextHolder {
    font-size: 20px;
    line-height: 1.5em;
    background-color: rgba(185, 213, 213);
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    position: absolute;
    border-width: 20px 26px 20px 20px;
    border-color: rgba(255, 255, 255, 0);
    margin-right: -92px !important;
    width: 550px;
    height: 200px;
    opacity: 0.3;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .headerText {
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    text-shadow: 2px 2px 5px rgb(27, 27, 27);
    line-height: 51px;
    padding: 40px;
    max-width: 500px;
    position: absolute;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 51px;
    color: #ffffff !important;
    /* bottom: 0;
    left: 0; */
  }
  
  .middleBody {
    text-align: center;
    padding: 20px;
    background-color: whitesmoke;
    margin: 0 10%;
  }
  
 /* .dronesDiv {
    text-align: left;
    padding-top: 80px;
    position: relative;
    min-height: 700px;
  } */
  
  .dronesText {
    margin: 0 20%;
  }
  
  .dronesImage {
    /* position: absolute;
    top: 50px;
    right: 0px; */
    margin: 50px auto;
    min-height: 500px;
    width: 60%;
    background-image: url('https://www.arboair.com/wp-content/uploads/2020/02/drone-1.png') !important;
    background-position: center center;
    background-color: #666;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .droneList {
    /* display: list-item */
    /* margin-left: 20px; */
    /* list-style: none; */
    text-align: left;
    display: inline-block;
    flex-direction: column;
    color:#070707;
  }
  
  .container h1{
    padding-top: 60px;
    font-family: 'Great Vibes', cursive;
    font-size: 60px;
  }
  
  .container h3{
    font-size: 22px;
    padding-top: 50px;
  }
  
  .container{
    text-align: left;
  }
  
  .line{
    border-bottom: 3px solid #070707;
    width: 100%;
    margin: 8px auto 10px 0;
  }
  
  /* .droneSteps {
    max-width: 500px;
    position: absolute;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 41px;
    color: #000000 !important;
    bottom: 0;
    left: 0;
    line-height: 51px;
    padding: 40px;
  } */
  