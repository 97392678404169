.content {
  
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainCard {
  position: relative;
  padding: 100px;
  min-width: 90%;
  text-align: center;
  border-radius: 10px;
  max-width:800px;
  background-image: url("https://cdn.dribbble.com/users/2811827/screenshots/11009457/media/9d9b728b896701a6ec5536fc7bbcb7a3.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height:800px; */
  display:flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px -2px 2px rgba(34,34,34,0.6);

}

.statsButton{
  position: absolute;
  display:flex;
  top: 30px;
  right: 30px;
  background-color: green;
  height: 40px;
  border-radius: 25px;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  color: white;
}

.profileImageWrapper {
  position: relative;
}

.formWrapper{
  margin-top:20px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 600px;
  border-radius: 20px;
  padding: 30px;
  
}