.webmap {
    height: 100%;
  
    z-index: 1;
  }
  .webmap-s {
    height: 90vh;
  }
  
  .mainFrame {
    height: 100%;
    width: 100%;
    -webkit-flex: 1 1 0;
    flex: 1 1 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  /* .navBar {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    z-index: 10;
    margin: 0;
    position: relative;
  }
  .navBarInner {
    position: absolute;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    padding-top: 15px;
    padding-left: 70px;
    padding-right: 180px;
    display: flex;
    justify-content: space-between;
  } */
  
  .loadingModalWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1)
      both;
    animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  .loadingModalWrapperExit {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 1;
    -webkit-animation: fade-out 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: fade-out 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  }
  .loadingModal {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
  }
  
  /* ----------------------------------------------
   * Generated by Animista on 2020-12-3 0:17:41
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * animation tracking-in-expand
   * ----------------------------------------
   */
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  .loadingModalExit {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    opacity: 1;
  }
  
  @keyframes fade-out {
    100% {
      opacity: 0;
      display: none;
      z-index: -1;
    }
  }
  
  .popupStyle {
    height: 100%;
    width: 100%;
    display: "flex";
    flex-direction: "row";
    justify-content: space-around;
    align-items: center;
    align-items: center;
  }
  
  .legend {
    line-height: 25px;
    color: #555;
    padding: 6px 8px;
    background: white;
    background: rgba(255,255,255,0.9);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  .legend h4 {
    margin: 0 0 5px;
    color: #777;
  }
  
  .legend i {
    width: 18px;
    height: 18px;
    line-height: 25px;
    float: left;
    margin-right: 8px;
    opacity: 0.9;
  }
  
  .info {
    padding: 6px 8px;
    width:150px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .mapType::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  /* Map Sidenav Settings */
  .mapType{
      position: fixed;
      background-color: white;
      bottom: 1rem;
      left: 1rem;
      border-radius: 10px;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
  
  .mapTypeOpen{ 
      width: 175px;
      height: 280px;
      animation: opening-animation 0.5s cubic-bezier(0, 1.13, 1, 1) forwards;
  }
  @keyframes opening-animation {
      100% {
        width: 225px;
        height: 300px;
      };
    }
  
  .mapTypeClose{
      padding: 1rem;
      position: relative;
  }
  
  .mapTypeClose:hover{
      background-color: rgb(187, 187, 187);
      color: rgb(124, 81, 81);
      border-radius: 10px;
  }
  
  .mapTypeOpen .mapHeader{
      padding: 1rem;
      border-radius: 10px 0 0 0;
      /* background-color: white;
      width: 223px;
      
      box-shadow: 0px 1px 4px -2px rgba(0,0,0,0.4); */
  }
  
  .contentRight{
      background: white;
      width: 330px;
      height: 90%;
      position: fixed;
      top: 80px;
      transition: all 0.5s ease 0s;
      border-radius: 30px 0 0 30px;
      -webkit-transition: all 0.5s ease 0s;
      z-index: 999;
      box-shadow: 8px -1px 8px 5px rgba(0,0,0,0.4);
      overflow-y: scroll;
  }
  .contentRight ::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
      
  .contentRight {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }
  .contentRight h5{
    font-size: 18px;
    font-weight: 600;
  }
  
  .contentRight p{
    font-size: 14px;
  }
  
  .overlayRight{
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: block;
      position: fixed;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
      overflow-x: hidden;
  }

  .contentLeft{
      background: white;
      width: 330px;
      height: 50%;
      position: fixed;
      top: 38%;
      transition: all 0.5s ease 0s;
      border-radius: 0 30px 30px 0;
      -webkit-transition: all 0.5s ease 0s;
      z-index: 999;
      box-shadow: 8px -1px 8px 5px rgba(0,0,0,0.4);
      overflow-y: scroll;
  }
  .contentLeft ::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
      
  .contentLeft {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }

  .contentLeft h5{
    font-size: 18px;
    font-weight: 600;
  }
  
  .contentLeft p{
    font-size: 14px;
  }
  
  .overlayLeft{
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: block;
      position: fixed;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
      overflow-x: hidden;
  }
  .buttonLeft{
    color: gray;
    border-radius: 30px 0 0 30px;
    padding: 310px 8px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 300px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
}

.buttonLeft:hover{
    color: black;
}
  
  .findForest{
      display: flex;
      padding: 0 0 0 1rem;
      margin: 2rem 2rem 1rem 2rem;
      border-style: none;
      box-shadow: 0px 2px 6px -2px rgba(0,0,0,0.4);
  }
  .findForest input{
      width: 100%;
      border-style: none;
  }
  form .searchIcon{
      width: 40px;
      height: 40px;
      padding: 0.3rem 0.4rem 0.3rem 0.5rem;
      color: white;
      background-color: rgb(192, 192, 192);
      cursor: pointer;
  }
  .searchIcon:hover{
      background-color: rgb(160, 160, 160);
  }
  .buttonRight{
      color: gray;
      border-radius: 30px 0 0 30px;
      padding: 310px 8px;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
  }
  
  .buttonRight:hover{
      color: black;
  }

  .tools{
      display: flex;
      flex-direction: column;
      margin: 2rem 2rem 0 2rem;
      text-transform: uppercase;
  }
  .tools p{
      text-transform: none;
      margin: 0.2rem 0 0 0;
  }
  .stats{
    display: flex;
    flex-direction: column;
    margin: 2rem 2rem 0 2rem;
  }
  .stats p{
      text-transform: none;
      margin: 0.2rem 0 0 0;
  }
  .stats h4{
    font-weight: 600;
  }
  .icons{
    display: block;
    align-items: center;
    margin: 10px auto;
    width: 30px;
    height: 30px;
  }
  .toolContainer{
      background-color: white;
      width: 120px;
      padding: 0 10px;
      margin: 2rem 0.3rem;
      box-shadow: 0px 2px 6px -2px rgba(0,0,0,0.4);
      cursor: pointer;
      height: 100px;
  }
  .toolContainer:hover{
      background-color: rgb(194, 207, 207);
      color: white;
  }
  .toolContainerResults{
      background-color: white;
      width: 120px;
      padding: 0 10px;
      margin: 0.1rem 0.3rem;
      box-shadow: 0px 2px 6px -2px rgba(0,0,0,0.4);
      cursor: pointer;
      height: 120px;
  }
  .toolContainerResults:hover{
      background-color: rgb(194, 207, 207);
      color: white;
  }
  .tools h4{
    font-weight: 600;
  }
  .tools span{
    text-transform: none;
    font-size: 18px;
  }
  .tools h5{
    padding: 0.8rem 0.6rem 0 0;
  }
  .tools h6{
    font-size: 12px;
    margin: 0.8rem 10%;
    text-align: center;
    font-weight: 600;
  }
  .tools h7{
    font-size: 11px;
    margin: 0.8rem 10%;
    text-align: center;
    font-weight: 600;
  }
  
  .toolsGroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -19px;
  }
  
  /* button settings on map */
  .nameForest{
      position: fixed;
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      margin: 0.5rem 0;
      left: 50%;
      transform: translate(-50%, 0);
      /* width: 550px; */
      border: none;
  }
  
  .backButton, .saveIcon{
      background-color: whitesmoke;
      width: 40px;
      height: 40px;
      font-size: 20px;
      border-radius: 10px;
      padding: 0.28rem 0.8rem;
      margin-right: 1rem;
      margin-left: 1rem;
    }
  .backButton:hover, .saveIcon:hover{
    background-color: rgb(129, 158, 158);
    color: white;
  }
  
  .zoomButtons, .undoRedoButtons{
      position: fixed;
      display: flex;
      flex-direction: column;
      margin: 10rem 1rem;
      color: gray;
      background-color: white;
      border-radius: 10px;
  }
  
  .undoRedoButtons{
      margin: 15rem 1rem;
  }
  
  .mainIcons{
      display: flex;
      flex-direction: row;
      color: gray;
      background-color: white;
      border-radius: 10px;
      position: fixed;
      margin: 6.5rem 1rem;
  }
  
  .navOnMap{
    z-index: 10;
    cursor: pointer;
  }
  
  .searchIconStyle{
    color: red;
  }
  
  .map{
    /* text-align: center; */
    top: calc(50% - 36px); /* 50% - 3/4 of icon height */
    height: 70px;
    width: 70px;
    background-color: rgb(176, 201, 156);
  }

  .icon{
    position:relative;
    top: calc(50% - 25px); /* 50% - 3/4 of icon height */
  }
  
  .mapGroup{
    padding: 0 20px 10px 20px;
  }
  
  .mapsContainer{
    margin: 0 auto;  
    padding-top: 60px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .closeIcon:hover{
    color: rgb(168, 168, 168);
    cursor: pointer;
  }
  
  .searchForest{
    color: red;
  }