.mainContent {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.headerImage {
  min-height: 400px;
  background-size: cover;
  background-image: url("https://www.arboair.com/wp-content/uploads/2020/02/trees-2-comp.jpg") !important;
  background-color: #666;
  opacity: 0.9;
  position: relative;
}
.headerTextHolder {
  font-size: 20px;
  line-height: 1.5em;
  background-color: rgba(185, 213, 213);
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  position: absolute;
  border-width: 20px 26px 20px 20px;
  border-color: rgba(255, 255, 255, 0);
  margin-right: -92px !important;
  width: 550px;
  height: 300px;
  opacity: 0.3;
  left: 50;
  bottom: 0;
  margin: 20px;
}
.headerText {
  max-width: 500px;
  position: absolute;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 51px;
  color: #ffffff !important;
  bottom: 0;
  left: 0;
  line-height: 51px;
  padding: 40px;
}

.middleBody {
  text-align: center;
  margin: auto;
  width: 90%;
  padding: 20px;
}
.visionDiv {
  font-size: 20px;
  text-align: center;
  align-items: center;
}

.ourStoryDiv {
  text-align: left;
  padding-top: 80px;
  position: relative;
  min-height: 700px;
}
.ourStoryText {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60%;
}

.ourStoryImage {
  position: absolute;
  top: 50px;
  right: 0px;

  min-height: 400px;
  width: 34%;
  background-image: url("https://www.arboair.com/wp-content/uploads/2020/02/trees-3-long-comp.jpg") !important;
  background-color: #666;
  background-size: cover;
  background-repeat: no-repeat;
}

.aimDiv{
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
  font-style: italic;
  font-weight: 800;
  color: #74b2b7 !important;
  font-size: 20px;
  text-align: center;
  align-items: center;
}

.teamDiv{
  padding-top:50px;
  text-align: center;
  margin-bottom:100px;
}

.foundersDiv{
  padding-top:60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.profileDiv{
  width:33%;
  padding-bottom: 50px;
  padding: 10px;
}