.backgroud {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container10 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapperGallery{
  flex: 1 1 auto;
  padding: 20px;
  -webkit-overflow-scrolling: touch;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
}

.dropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  border: 4px dashed #a0adba;
  border-radius: "50%";
}

.mapViewDrop {
  flex: 1 0 auto;
  height: 50vh;
  width: 100%;
}

.uploadIcon {
  width: 50px;
  height: 50px;
  background: url("../images/upload.png") no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.dropMessage {
  text-align: center;
  color: #a0adba;
  font-size: 20px;
}
.fileDisplayContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* renamed to flex-start for better support */
  align-content: flex-start; 
  justify-content: center;
}

.fileStatusBar {
  vertical-align: top;
  position: relative;
  line-height: 50px;
  display: inline;
}

.fileStatusBar > div {
  overflow: hidden;
}

.fileType {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}
.objectContainer {
  flex: 1 1 auto;
}
.imageContainer {
  cursor: pointer;
}
.fileTitle {
  display: flex;
  flex-direction: column;
}
.fileName {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
  cursor: pointer;
}

.fileError {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.fileErrorMessage {
  color: red;
}

.fileTypeLogo {
  width: 50px;
  height: 50px;
  background: url(../images/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.fileSize {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}
.imgGallery{
  max-width: 300px;
}
.fileRemove {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 15px;
  cursor: pointer;
  color: red;
}

.modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.modal .modalImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 90%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.btnDropzone {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}
.buttonDropzone {
  margin-right: 5px;
  margin-bottom: 15px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.fileInput {
  display: none;
}

.uploadModal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.uploadModal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.progressContainer {
  background: white;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.progressContainer span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
}

.progress {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  /* background-color: #ff0000; */
  height: 20px;
  border-radius: 5px;
  text-align: center;
}

.progressBar {
  /* position: absolute; */
  background-color: #4aa1f3;
  height: 20px;
  border-radius: 5px;
  /* text-align: center; */
  /* color: white; */
  /* font-weight: bold; */
}

.progressText {
  position: absolute;
  text-align: center;
  /* width: 100%; */
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.error {
  color: red;
}

li {
  /*list-style-image: url(/right-arrow.svg);
   OR */
  /* list-style-type: '👉'; */
  padding-inline-start: 2ch;
  padding-left: 10px;
}