.fullScreenVideoWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}
.videoOverlay {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.video {
  min-width: 100%;
  min-height: 100%;
}