.wrapper {
    display: grid;
    grid-template-columns: 5% 95%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    z-index: 2;
  }
  
  span,
  .logo {
    height: 70px;
    align-self: center;
    justify-self: center;
  }
  
  .burgerIcon {
    display: flex;
    margin-left: 2rem;
    height: 80px;
    font-size: 2rem;
    color: #333;
  }
  
  /* Profile */
  .profileContainer {
    color: rgb(255, 255, 255);
    font-size: 18px;
    position: relative;
    text-align: center;
  }
  
  .profileAvatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  
  .profileName {
    padding-top: 10px;
    font-weight: 600;
    margin-bottom: 0rem !important;
  }
  
  .myProfile {
    color: rgb(212, 178, 66);
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    padding: 0px !important;
  }
  
  .myProfile:hover {
    color: rgb(165, 141, 61);
  }
  
  /* Navbar */
  .navbar {
    background-color: rgb(255, 255, 255);
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  
  .menuBarClose {
    margin-top: 0.5rem;
    margin-right: 1.5rem;
    font-size: 2rem;
    color: whitesmoke;
  }
  
  .navMenu {
    background-color: #396f78;
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    transition: 350ms;
    left: -100%;
    z-index: 3;
  }
  
  .navMenuActive {
    background-color: #396f78;
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 3;
    left: 0;
    transition: 350ms;
  }
  
  .navText {
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 0px;
    list-style: none;
  }
  
  .navText a {
    text-decoration: none;
    color: whitesmoke;
    font-size: 18px;
    padding-left: 40px;
    width: 100%;
    display: 100%;
    align-items: center;
    line-height: 60px;
  }
  
  .navText a:hover {
    background-color: #73b4c0;
    color:  #396f78;
    margin: 0;
    border-left: 3px solid #ffffff;
  }
  
  .navMenuItems {
    width: 100%;
    padding: 0;
  }
  
  .navbarToggle {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

  .overlay {
    height: 100%;
    width: 100%;
    display: "block";
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-x: hidden;
    animation: overlay-opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  @keyframes overlay-opacity {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .logo {
      display: none;
    }

    .topbar{
      height: 20px;
    }

    .navMenu {
      background-color: #396f78;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      transition: 350ms;
      left: -150%;
      z-index: 3;
    }

    .navMenuActive {
      background-color: #396f78;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      transition: 350ms;
      z-index: 3;
    }
  
    .burgerIcon {
      display: flex;
      justify-content: flex-end;
      margin-left: 2rem;
      height: 80px;
      font-size: 2rem;
      color: #333;
    }
  }

