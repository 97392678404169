/* .item must be first */
.item {
  align-items: center;
  color: rgb(80, 65, 65);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.arrow{
  align-items: center;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.disabled-page {
  color: #777777;
}

/* .active {
  color: #ffffff;
} */

.break-me {
}

.next {
  /* border-left: solid 1px #ff0000; */
  font-size: 4px;
  height: 90px;
  position: absolute;
  right: 0;
  width: 150px;
}

.pagination {
  align-items: center;
  background-color: #41994c;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 100%;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  /* border-right: solid 1px #ffffff; */
  font-size: 4px;
  height: 90px;
  left: 0;
  position: absolute;
  width: 150px;
}