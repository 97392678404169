.flexColumnDiv {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.flexRowDiv {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row;
  justify-content: center;
  line-height: 70px;
  font-size: large;
  width: 100%;
  height: 70px;
}
/* Fix for bootstrap setting margin bottom 1rem */

p {
  margin-bottom: 0px !important;

}
/* #Admin p{

  font-size: 34px;

} */

/* h2 {
  color: rgb(0, 0, 0);
  display: inline;
  float: left;
  font-size: 50px; 
  vertical-align: middle;
  font-weight: 550;
  margin-left: 90px;
} */

/* .mainList {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
  position: relative;
  width: 100%;
} */

/* Main Content Wrapper */
.contentMF {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  z-index: 0;
  background-color: #386e6e;
}
.containerContentMF {
  background-color: #fff;
  margin: 5% 10%;
  padding: 20px;
  position: relative;
}
.containerContentMF1 {
  background-color: #fff;
  margin: 5% 10%;
  padding: 0px;
  position: relative;
}
/* Sub Content Wrapper for Admin subpages */

.containerSubAdmin {
  background-color: rgb(255, 255, 255);
  margin: 0% 00%;
  padding: 0px;
  position: relative;
}


/* Page Top container */
.containerOneMF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 /* padding: 3rem 0;
  */
}
/* Page Title Box */
.boxOneMF {
  display: flex;
  color: rgb(0, 0, 0);
  flex: 1;
  justify-content: flex-start;
  padding: 0 50px;
}

/* Button row Select/Order/Delete All */
.containerTwoMF {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

/* Page title + button boxes */
.boxOneMF,.boxThreeOptionsMF {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 50px;
}
.boxTwoMF,.boxFourSelected {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 50px;
}

/* SortingRow*/
.contFirstA {
  display: grid;
  grid-template-columns: 10% repeat(6, 20%);
  grid-template-rows: 5rem;
  margin: 0px 50px;
  box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
  background-color: #fff;
}
.contFirstA .elementThumbnail{
  display: none;
}
/* SortingRow Results*/
.contFirstR {
  display: grid;
  grid-template-columns: 24% repeat(6, 14%);
  grid-template-rows: 5rem;
  margin: 0px 50px;
  box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
  background-color: #fff;
}

/*Main content boxes Forests*/
.contSecondMF {
  display: grid;
  position: relative;
  grid-template-columns: 5% 10% 25% repeat(4, 12%) 10%;
  grid-template-rows: 200px;
  box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
  background-color: #fff;
  margin: 10px 50px;
}

/*Main content boxes results*/
.contSecondR {
  display: grid;
  position: relative;
  grid-template-columns: 28% repeat(6, 14%);
  grid-template-rows: 200px;
  box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
  background-color: #fff;
  margin: 10px 50px;
}

/* SortingRow Categories + Information */
.elementCheck, .elementThumbnail, .elementForestName, .elementLatestAn ,.elementResultsName {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  /* color: rgb(67, 87, 124); */
  color: rgb(67, 87, 124);
}
.elementBtnA {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  color: rgb(67, 87, 124);
}
.elementCondition,.elementSize {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  color: rgb(67, 87, 124);
}
.elementBtnRow, .elementBtnRowR{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
/* Result information */
.elementResultsName, .elementAnalysis, .elementNameUser, .elementForestName, .elementDate, .elementTime, .elementResultsStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: 600;
  color: rgb(67, 87, 124);
}
.elementIDA {
  font-size: 12px;
}
.elementProgress{
  display: none;
}
.elementEmail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: 600;
  color: rgb(67, 87, 124);
  white-space: normal;
  word-break:break-all;
  width: 120%;
}


/* Buttons */
.btnMainMF {
  background-color: #0d666e;
  color: #808080;
  border: 0 solid;
  letter-spacing: 0.1rem;
  cursor: pointer;
  padding: 15px 25px;
  margin: 5px;
  font-size: 16px;
}
.btnMainMF:hover {
  background-color: #09474d;
}
.btnTwoMF {
  background-color: #707070;
  color: #fff;
  border: 0 solid;
  letter-spacing: 0.1rem;
  cursor: pointer;
  padding: 10px 25px;
  margin: 5px;
  font-size: 16px;
}
.btnTwoMF:hover {
  background-color: #333333;
}
.btnThreeMF {
  background-color: #0d666e;
  color: #808080;
  border: 0 solid;
  letter-spacing: 0.1rem;
  cursor: pointer;
  padding:0.35em 1.2em;
  margin:0 0.3em 0.3em 0;
  font-size: 16px;
  transition: all 0.2s;
  text-align:center;
}
.btnThreeMFs {
  border-radius: 0.12em;
  margin-left: 20px;
  box-sizing: border-box;
  text-decoration: none;
  font-family:'Roboto',sans-serif;
  font-weight: 300;
  color:#FFFFFF;
  text-align: center;
  transition: all 0.2s;
}
.btnThreeMF:hover {
  background-color: #09474d;
}
.btnSecondaryForest {
  display: flex;
  font-size: 45px;
  padding: 5px;
  margin: 5px;
  color: #fff;
  cursor: pointer;
  background-color: #7eb6b1;
}
.btnSecondaryForest:hover {
  background-color: #3e9b93;
}
.btnDelForest {
  display: flex;
  font-size: 45px;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #e0a956;
  cursor: pointer;
}
.btnDelForest:hover {
  background-color: #bd893a;
}
.btnSecondaryA {
  display: flex;
  font-size: 45px;
  padding: 5px;
  margin: 5px;
  color: #fff;
  cursor: pointer;
  background-color: #acc6c4;
}
.btnSecondaryA:hover {
  background-color: #3e9b93;
}
.btnSecondaryB {
  display: flex;
  font-size: 45px;
  padding: 0px;
  margin: 5px;
  color: #fff;
  cursor: pointer;
  background-color: #000101;
}
.btnSecondaryB:hover {
  background-color: #3e9b93;
}
.btnDelA {
  display: flex;
  font-size: 45px;
  padding: 5px;
  margin: 5px;
  color: #fff;
  background-color: #e0a956;
  cursor: pointer;
}
.btnDelA:hover {
  background-color: #bd893a;
}
.areaPicker {
  overflow: hidden;
}
/* Search Bar */
.searchBar {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 2%;
  margin-right: 25%;
}
.searchBarWrapper {
  width: 100%;
  top: 20px;
  padding: 10px;
}

/* Side Menu Filter */
.contentSideBar{
  background: rgb(255, 255, 255);
  width: 330px;
  height: 90%;
  position: fixed;
  top: 80px;
  transition: all 0.5s ease 0s;
  border-radius: 30px 0 0 30px;
  -webkit-transition: all 0.5s ease 0s;
  z-index: 999;
  box-shadow: 8px -1px 8px 5px rgba(0,0,0,0.4);
}
/* Side Filter Contents */
.contentSideBar h5{
  font-size: 18px;
  font-weight: 600;
}
.contentSideBar p{
  font-size: 14px;
}
/* Side Filter button */
.button{
  color: rgb(0, 0, 0);
  padding: 310px 8px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.button:hover{
  color: #3e9b93;
  ;
}
.icons{
  display: block;
  align-items: center;
  margin: 10px auto;
  width: 30px;
  height: 30px;
}
.filterText{
 /* background-color: white;
  max-width: 100px;
  */
  padding: 0 10px;
  margin: 0.1rem 0.3rem;
 /* box-shadow: 0px 2px 6px -2px rgba(0,0,0,0.4);
  */
  cursor: pointer;
}
.filters{
  display: flex;
  flex-direction: column;
  margin: 2rem 2rem 0 2rem;
}
.filters p{
  text-transform: none;
  margin: 0.2rem 0 0 0;
}
.filters h4{
  font-weight: 600;
}
.toolsGroup{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mosaicForestMF {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Pagination */
.pagination {
  align-items: center;
  background-color: #41994c;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 100%;
}
/* Pagination Results */
.paginationA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin: 0px 50px;
  padding: 0;
}






.contSecondAdmin {
  display: grid;
  position: relative;
  grid-template-columns: 15% repeat(6, 18%);
  grid-template-rows: 60px;
  box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
  background-color: #fff;
  margin: 10px 50px;
}


/* 
.sideMenuAdmin{
}
.sideMenuAdmin ul{
font-size: 20px;
background: #ffffff;
padding: 20px 20px 20px 50px; 

}
.sideMenuAdmin li {
  color: #000000;
  } 
.sideMenuAdmin li a{
color: #000000;
} 
.sideMenuAdmin li a:hover {
  color: #386e6e;
}  */
.adminLinks .a{
  color: #000000!important;

}
.container { 
  min-height: 100%;
  height: 100%;
  
}
#Admin a{


}
.fillerBox{
  height: 200px;

}
.sideMenuAdminT {
  font-size: 20px;
  background: #ffffff;
  padding: 20px 20px 20px 50px; 
  display: inline;
  }
  .sideMenuAdminT li {
    color: #000000;

    } 
  .sideMenuAdminT li a{
  color: #000000;
  } 
  .sideMenuAdminT li a:hover {
    color: #386e6e;
  } 

  .teamList{
    margin-left: 52px;
    margin-top: 20px;
    background-color: #FFFFFF;
    width: 50%;
    padding: 20px;
  }
  .teamPage{
    margin-left: 161px;
  }

.boxAdmin{

  padding-top: 150px;
}
.groupSelect{
width: 100px !important;

}
.btnAdmin {
  background-color: #0d666e;
  color: #fff;
  border: 0 solid;
  letter-spacing: 0.1rem;
  cursor: pointer;
  padding: 15px 20px;
  margin: 5px;
  font-size: 16px;
  position: relative;
  width: 150px;
}
.btnAdmin:hover {
  background-color: #09474d;
}
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
/* .container{
  padding: 30px;

} */


/* Feedback */
@keyframes opening-animation {
  100% {
      width: 200px;
 }
}
.animatedFeedbackIcon {
  position: relative;
}
@keyframes closing-animation {
  100% {
      width: 40px;
 }
}
.animatedFeedbackText {
  opacity: 1;
  animation: text-animation-fade 1s cubic-bezier(0, 1.13, 1, 1) forwards 1500ms;
}
@keyframes text-animation-fade {
  100% {
      opacity: 0;
 }
}
.animatedFeedbackText:hover {
  opacity: 0;
  animation: text-animation-pop 0.5s cubic-bezier(0, 1.13, 1, 1) forwards 0.1s;
}
@keyframes text-animation-pop {
  100% {
      opacity: 1;
 }
}
/* Media Queries */
/* Ipad size */
@media (max-width: 1024px) {
  body {
      background-color: rgb(115, 223, 0);
 }
  .contentMF {
      background-color: rgb(103, 126, 77);
 }
  .containerContentMF {
      width: 100%;
      margin: 0;
      padding: 0;
 }
  .searchBar {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-left: 2%;
      margin-right: 25%;
 }
  .contFirstR {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 5rem;
      margin: 0px 50px;
      box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
      background-color: #fff;
 }
  .contFirstR .elementAnalysis{
      grid-column: 3;
      grid-row: 1;
 }
  .contFirstR .elementResultsStatus{
      grid-column: 4;
      grid-row: 1;
 }
  .contFirstR .elementDate {
      grid-column: 6;
      grid-row: 1;
 }
  .contFirstR .elementTime, .elementNameUser {
      display: none;
 }
 /*Main content boxes results*/
  .contSecondR {
      display: grid;
      position: relative;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 100px;
      box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
      background-color: #fff;
      margin: 10px 50px;
      white-space: normal;
      word-break:break-all;
 }
  .elementTime{
      grid-column: 4;
      grid-row: 2;
 }
  .elementDate{
      grid-column: 4;
      grid-row: 1;
 }
  .elementEmail, .elementEmpty{
      display: none;
 }
  .contSecondR .elementBtnRow {
      grid-column: 2;
      grid-row: 2;
 }
  .containerOneMF {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
 }
  .contFirstA {
      display: none;
 }
  .contSecondMF {
      display: grid;
      position: relative;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 100px;
      box-shadow: 2px 2px 4px 4px rgb(233, 233, 233);
      background-color: #fff;
      margin: 10px 20px;
 }
  .elementCondition, .elementLatestAn{
      display: none;
 }
  .elementCheck{
      width: 20%;
      margin-left: 40%;
      grid-column: 1;
      grid-row: 2;
 }
  .elementThumbnail{
      grid-column: 1;
      grid-row: 1;
 }
  .elementBtnA {
      padding: 0px;
      font-weight: 600;
      height: 100%;
      grid-column: 2;
      grid-row: 2;
 }
  .elementBtnRow{
      flex-direction: row;
      padding: 20px;
      grid-column: 3;
      grid-row: 2;
 }
 .elementBtnRowR{
  flex-direction: row;
  padding: 20px;
  grid-column: 3;
  grid-row: 1;
}
}
/* Mobile Size */
@media (max-width: 575px) {
  body {
      background-color: rgb(255, 255, 255);
 }
  .containerContentMF {
      width: 100%;
      margin: 0;
      padding: 0;
 }
  .boxTwoMF {
      padding: 0;
 }
  .btnMainMF {
      padding: 15px 15px;
      margin: 5px;
 }
  .boxFourSelected {
      display: none;
 }
  .containerTwoMF {
      display: none;
      margin: 0;
 }
  .containerThreeMF {
      display: none;
 }
  .checkboxForestMF {
      display: none;
 }
  .containerOneMF {
      display: flex;
      flex-direction: column;
      margin: 0px;
 }
  .boxOneMF {
      padding: 0px 0px 25px;
 }
  .containerTwoItemsMF {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 50px 50px;
      margin: 0;
 }
  .containerOneItemsMF {
      display: none;
 }
  .forestAndIdMF {
      display: flex;
      align-items: center;
 }
  .optionIconsMF {
      display: flex;
      flex-direction: row;
 }
  .contFirstA {
      display: none;
 }
  .elementIDA {
      font-size: 8px;
 }
  .contSecondMF {
      grid-template-columns: repeat(3, 1fr);
      margin: 10px 20px;
 }
  .elementCheck{
      padding: 0px;
      margin-left: 0px;
      font-weight: 600;
      width: 20%;
      height: 50%;
      grid-column: 1;
      grid-row: 1;
 }
  .elementForestName{
      padding: 40px;
      grid-column: 1;
      grid-row: 1;
      margin-top: 15px;
 }
  .elementThumbnail , .elementLatestAn, .elementCondition{
      display: none;
 }
  .elementSize{
      padding: 20px;
      height: 100px;
      grid-column: 2;
      grid-row: 1;
 }
  .elementBtnA {
      padding: 0px;
      font-weight: 600;
      height: 100%;
      grid-column: 2;
      grid-row: 2;
 }
  .elementBtnRow{
      flex-direction: row;
      padding: 20px;
      grid-column: 1;
      grid-row: 2;
 }
 .elementBtnRowR{
  flex-direction: row;
  padding: 20px;
  grid-column: 2;
  grid-row: 1;
}
  .contFirstR {
      grid-template-columns: repeat(3, 1fr);
      margin: 0px 30px;
 }
  .contSecondR {
      grid-template-columns: repeat(2, 1fr);
      margin: 10px 30px;
 }
  .elementTime{
      grid-column: 2;
      grid-row: 3;
 }
  .elementDate{
      grid-column: 2;
      grid-row: 2;
 }
  .elementProgress{
      grid-column: 1;
      grid-row: 3;
 }
  .elementAnalysis{
      grid-column: 1;
      grid-row: 2;
 }
  .btnThreeMF {
      width: 100%;
      display:block;
 }
}
