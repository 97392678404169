.headerTitle {
  color: rgb(0, 0, 0);
  display: inline;
  float: left;
  font-size: xx-large;
  vertical-align: middle;
  font-weight: 550;
  margin-left: 90px;
}

.Logo {
  background-image: url(../images/dashboard.png);
}

.header {
  background: #ffffff;
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 80px;
  height: 80px;
  flex: 0 1 auto;
}
.header {
  background: #ffffff;
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 80px;
  height: 95px;
  flex: 0 1 auto;
}


.headerRight {
  float: right;
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  font-size: large;
  padding-right: 20px;
}

.feedbackButton{
  cursor: pointer;
  border-radius: 40px;
  position: absolute;
  height: 40px;
  top: 60px;
  z-index: 1;
  right: 20px;
  background-color: rgb(91, 155, 252);
  line-height: 20px;
  color: white;
  padding: 10px;
  width: 120px;
  display: flex;
  animation: closing-animation 1s cubic-bezier(0, 1.13, 1, 1) forwards 1500ms;
}
.feedbackButton:hover{
  width:40px;
  animation: opening-animation 0.25s cubic-bezier(0, 1.13, 1, 1) forwards;
}
@keyframes opening-animation {
  100% {
    width: 120px;
  };
}
.animatedFeedbackIcon{
  position:relative;
  
}
@keyframes closing-animation {
  100% {
    width: 40px;
  };
}

.animatedFeedbackText{
  opacity: 1;
  animation: text-animation-fade 1s cubic-bezier(0, 1.13, 1, 1) forwards 1500ms;
}

@keyframes text-animation-fade {
  100%{
    opacity: 0;
  }
}

.animatedFeedbackText:hover{
  opacity: 0;
  animation: text-animation-pop 0.50s cubic-bezier(0, 1.13, 1, 1) forwards 0.1s;
}


@keyframes text-animation-pop {
  100%{
    opacity: 1;
  }
}